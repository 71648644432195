import { ref } from "vue";
import { getVimeoEmbedThumbnail } from "@/shared/utils/vimeo";

const vimeoThumbnails = ref<{ [url: string]: string }>({});

export const useVimeoEmbed = () => {
  const getVimeoThumbnail = (url: string) => {
    if (!url) {
      return "";
    }

    if (!vimeoThumbnails.value[url]) {
      setVimeoThumbnail(url);
    }

    return vimeoThumbnails.value[url] ?? "#";
  };

  const setVimeoThumbnail = async (url: string) => {
    try {
      vimeoThumbnails.value[url] = await getVimeoEmbedThumbnail(url, {
        // Also sets the thumbnail quality
        width: 1920,
      });
    } catch (e) {
      vimeoThumbnails.value[url] = "";
    }
  };

  return { vimeoThumbnails, getVimeoThumbnail };
};
