import { ContentType } from "@/../__generated__/globalTypes";
import { CreatorContentSubmissions_creatorContentSubmissions_CreatorContentSubmissionResults_results } from "@/api/creatorContent/__generated__/CreatorContentSubmissions";

type CreatorContent = Pick<
  CreatorContentSubmissions_creatorContentSubmissions_CreatorContentSubmissionResults_results,
  | "contentType"
  | "photoPrice"
  | "videoPrice"
  | "photo"
  | "photoThumbnail"
  | "video"
>;

export const getCreatorContentDetails = (
  creatorContent: CreatorContent | null | undefined
) => {
  const parsedData = {
    price: 0,
    media: "",
    photoThumbnail: "",
    isPhoto: false,
    isVideo: false,
  };

  const type = creatorContent?.contentType;
  if (type === ContentType.PHOTO) {
    parsedData.price = Number(creatorContent?.photoPrice || 0);
    parsedData.photoThumbnail = creatorContent?.photoThumbnail ?? "#";
    parsedData.media = creatorContent?.photo ?? "#";
    parsedData.isPhoto = true;
  } else if (type === ContentType.VIDEO) {
    parsedData.price = Number(creatorContent?.videoPrice || 0);
    parsedData.media = creatorContent?.video ?? "";
    parsedData.isVideo = true;
  }

  return parsedData;
};
