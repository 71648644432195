
import CreatorContentMediaListItem from "@/shared/components/CreatorContent/CreatorContentMediaListItem.vue";
import DiscountedPrice from "@/shared/components/DiscountedPrice.vue";
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import InputPhoneNumber from "@/shared/components/Forms/InputPhoneNumber.vue";
import InputText from "@/shared/components/Forms/InputText.vue";
import PageLayout from "@/shared/components/Layouts/PageLayout.vue";
import { useCreatorContentCart } from "@/shared/composables/useCreatorContentCart";
import {
  makeEmailRule,
  makeRequiredRule,
} from "@/shared/utils/validators/commonValidators";
import router from "@/web/router";
import routeNames from "@/web/router/routeNames";
import { defineComponent, reactive } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  props: {},
  setup() {
    const { t, n } = useI18n();

    const formState = reactive({
      name: "",
      email: "",
      phone: "",
      socialMediaLink: "",
    });

    const {
      cartList,
      cartListLoading,
      checkoutCart,
      checkoutCartLoading,
      clearCart,
      cartPrice,
      formattedCartPrice,
      merchantBrandFee,
      originalBrandFee,
      grandTotal,
    } = useCreatorContentCart();

    const handleCheckout = async () => {
      const success = await checkoutCart(formState);

      if (success) {
        router.push({ name: routeNames.creatorContentThankYou });
      }
    };

    return {
      t,
      n,
      routeNames,
      makeEmailRule,
      formState,
      cartList,
      cartListLoading,
      handleCheckout,
      checkoutCartLoading,
      clearCart,
      cartPrice,
      formattedCartPrice,
      merchantBrandFee,
      originalBrandFee,
      grandTotal,
    };
  },
  methods: { makeRequiredRule },
  components: {
    PageLayout,
    FormGroup,
    InputText,
    InputPhoneNumber,
    CreatorContentMediaListItem,
    DiscountedPrice,
  },
});
