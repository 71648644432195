export const VIMEO_OEMBED_URL = "https://vimeo.com/api/oembed.json";

/** From https://developer.vimeo.com/api/oembed/videos */
export type VimeoOembedOptions = Partial<{
  url: string;
  autoplay: boolean;
  loop: boolean;
  width: number;
  height: number;
  responsive: boolean;
  title: boolean;
}>;

/** From https://developer.vimeo.com/api/reference/response/video */
export type VimeoVideo = Partial<{
  html: string;
  thumbnail_url: string;
  thumbnail_url_with_play_button: string;
}>;

export type VimeoVideoStatus =
  | "available"
  | "quota_exceeded"
  | "total_cap_exceeded"
  | "transcode_starting"
  | "transcoding"
  | "transcoding_error"
  | "unavailable"
  | "uploading"
  | "uploading_error";

export const getOembedData = async (
  url: string,
  options?: VimeoOembedOptions
): Promise<VimeoVideo | null> => {
  if (!url) return null;

  const _url = new URL(VIMEO_OEMBED_URL);

  _url.searchParams.set("url", url);

  if (options) {
    Object.keys(options).forEach((key) => {
      if (options[key]) {
        _url.searchParams.set(key, options[key].toString());
      }
    });
  }

  const response = await fetch(_url.toString());
  const data = await response.json();

  if (response.ok) {
    return data as VimeoVideo;
  } else {
    console.error("Error in getting Vimeo embed data using oEmbed API.", data);
    return null;
  }
};

export const getVimeoEmbedHtml = async (
  url: string,
  options?: VimeoOembedOptions
): Promise<string> => {
  try {
    return (await getOembedData(url, options))?.html ?? "";
  } catch (e) {
    return "";
  }
};

export const getVimeoEmbedThumbnail = async (
  url: string,
  options?: VimeoOembedOptions
): Promise<string> => {
  try {
    return (await getOembedData(url, options))?.thumbnail_url ?? "";
  } catch (e) {
    return "";
  }
};

export const createVimeoEmbeddedScript = () => {
  const script = window.document.createElement("script");
  script.setAttribute("src", "https://player.vimeo.com/api/player.js");
  script.async = true;
  return script;
};
