import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "discounted-price" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_text = _resolveComponent("a-typography-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.originalPricePlacement === 'top' && _ctx.showDiscounted && _ctx.originalPrice)
      ? (_openBlock(), _createBlock(_component_a_typography_text, {
          key: 0,
          class: "discounted-price__original"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.n(_ctx.originalPrice, "currency", "ph")), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_a_typography_text, {
      class: _normalizeClass(["discounted-price__current", [_ctx.currentPrice === 0 && 'discounted-price__current--free']])
    }, {
      default: _withCtx(() => [
        (_ctx.currentPrice === 0)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.t("FREE")), 1))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createTextVNode(_toDisplayString(_ctx.n(_ctx.currentPrice, "currency", "ph")), 1)
            ], 64))
      ]),
      _: 1
    }, 8, ["class"]),
    (
        _ctx.originalPricePlacement === 'bottom' && _ctx.showDiscounted && _ctx.originalPrice
      )
      ? (_openBlock(), _createBlock(_component_a_typography_text, {
          key: 1,
          class: "discounted-price__original"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.n(_ctx.originalPrice, "currency", "ph")), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}