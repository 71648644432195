import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-6 mt-16" }
const _hoisted_2 = { class: "flex justify-between mt-20" }
const _hoisted_3 = { class: "flex justify-between mt-8 flex-wrap gap-4" }
const _hoisted_4 = { class: "flex-1" }
const _hoisted_5 = { class: "flex justify-between mt-8 flex-wrap gap-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_CreatorContentMediaListItem = _resolveComponent("CreatorContentMediaListItem")!
  const _component_DiscountedPrice = _resolveComponent("DiscountedPrice")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputPhoneNumber = _resolveComponent("InputPhoneNumber")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_PageLayout = _resolveComponent("PageLayout")!

  return (_openBlock(), _createBlock(_component_PageLayout, { class: "creator-content-summary-page" }, {
    default: _withCtx(() => [
      (_ctx.cartListLoading)
        ? (_openBlock(), _createBlock(_component_a_skeleton, {
            key: 0,
            active: ""
          }))
        : (_openBlock(), _createBlock(_component_a_card, {
            key: 1,
            class: "creator-content-summary-page__card mb-20 lg:mb-48 mt-20",
            bordered: false
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, {
                gutter: [{ xs: 0, sm: 84, md: 168 }, 42],
                class: "creator-content-summary-page__grid"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, {
                    span: 24,
                    lg: 12
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_typography_title, {
                        level: 3,
                        class: "m-0 mb-2 leading-none"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("Order Summary")), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_typography_text, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("For guest. Have an acccount? ")) + " ", 1),
                          _createVNode(_component_router_link, {
                            to: { name: _ctx.routeNames.login }
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t("Log in.")), 1)
                            ]),
                            _: 1
                          }, 8, ["to"])
                        ]),
                        _: 1
                      }),
                      _createElementVNode("div", _hoisted_1, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cartList, (cartItem) => {
                          return (_openBlock(), _createBlock(_component_CreatorContentMediaListItem, {
                            key: cartItem?.id,
                            details: cartItem
                          }, null, 8, ["details"]))
                        }), 128))
                      ]),
                      _createElementVNode("div", _hoisted_2, [
                        _createVNode(_component_a_typography_title, {
                          level: 6,
                          class: "m-0"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t("Creator Fee")), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_typography_title, {
                          level: 5,
                          class: "m-0 text-right"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.formattedCartPrice), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, [
                          _createVNode(_component_a_typography_title, {
                            level: 6,
                            class: "m-0"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t("Platform Fee*")), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_typography_text, { class: "text-color-2" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t("*Limited offer.")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _createVNode(_component_DiscountedPrice, {
                          "original-price": _ctx.originalBrandFee,
                          "current-price": _ctx.merchantBrandFee,
                          "original-price-placement": "bottom",
                          class: "font-bold text-2xl flex-1"
                        }, null, 8, ["original-price", "current-price"])
                      ]),
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_a_typography_title, {
                          level: 4,
                          class: "m-0 flex-1"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t("DISCOUNTED TOTAL")), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_DiscountedPrice, {
                          "original-price": _ctx.cartPrice + _ctx.originalBrandFee,
                          "current-price": _ctx.cartPrice + _ctx.merchantBrandFee,
                          "original-price-placement": "bottom",
                          class: "font-bold text-2xl flex-1"
                        }, null, 8, ["original-price", "current-price"])
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, {
                    span: 24,
                    lg: 12
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_typography_title, {
                        level: 2,
                        class: "m-0 mb-6"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("Step 2: Checkout")), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_typography_text, { class: "block mb-11" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("Please fill up the form.")), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_FormGroup, {
                        model: _ctx.formState,
                        loading: _ctx.checkoutCartLoading,
                        onFinish: _ctx.handleCheckout
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_InputText, {
                            name: "name",
                            label: _ctx.t('Name'),
                            value: _ctx.formState.name,
                            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.name) = $event)),
                            rules: [_ctx.makeRequiredRule(_ctx.t('This field is required.'))],
                            placeholder: _ctx.t('Full Name')
                          }, null, 8, ["label", "value", "rules", "placeholder"]),
                          _createVNode(_component_InputText, {
                            name: "email",
                            label: _ctx.t('Email'),
                            value: _ctx.formState.email,
                            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.email) = $event)),
                            rules: [
                  _ctx.makeRequiredRule(_ctx.t('This field is required.')),
                  _ctx.makeEmailRule(_ctx.t('Please enter a valid email address.')),
                ],
                            placeholder: _ctx.t('username@domain.com')
                          }, null, 8, ["label", "value", "rules", "placeholder"]),
                          _createVNode(_component_InputPhoneNumber, {
                            name: "phone",
                            label: _ctx.t('Phone'),
                            value: _ctx.formState.phone,
                            "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.phone) = $event)),
                            rules: [_ctx.makeRequiredRule(_ctx.t('This field is required.'))]
                          }, null, 8, ["label", "value", "rules"]),
                          _createVNode(_component_InputText, {
                            name: "socialMediaLink",
                            label: _ctx.t('IG/FB Account'),
                            value: _ctx.formState.socialMediaLink,
                            "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formState.socialMediaLink) = $event)),
                            rules: [_ctx.makeRequiredRule(_ctx.t('This field is required.'))],
                            placeholder: _ctx.t('@')
                          }, null, 8, ["label", "value", "rules", "placeholder"]),
                          _createVNode(_component_a_button, {
                            type: "primary",
                            "html-type": "submit",
                            class: "mt-16 w-72 h-12",
                            loading: _ctx.checkoutCartLoading,
                            disabled: _ctx.checkoutCartLoading
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t("Submit")), 1)
                            ]),
                            _: 1
                          }, 8, ["loading", "disabled"])
                        ]),
                        _: 1
                      }, 8, ["model", "loading", "onFinish"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
    ]),
    _: 1
  }))
}