import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "creator-content-media-list-item__image" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createBlock(_component_a_row, {
    gutter: 20,
    align: "middle",
    class: "creator-content-media-list-item"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_col, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_ctx.parsedDetails.isPhoto)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: _ctx.parsedDetails.photoThumbnail,
                  class: "w-full h-full object-cover"
                }, null, 8, _hoisted_2))
              : (_ctx.parsedDetails.isVideo)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 1,
                    src: _ctx.getVimeoThumbnail(_ctx.parsedDetails.media),
                    class: "w-full h-full object-cover"
                  }, null, 8, _hoisted_3))
                : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_a_col, {
        flex: "1",
        class: "flex justify-between"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createVNode(_component_a_typography_title, {
              level: 6,
              class: "m-0"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.details?.contentType), 1)
              ]),
              _: 1
            }),
            (_ctx.details?.creatorName)
              ? (_openBlock(), _createBlock(_component_a_typography_text, { key: 0 }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("by {name}", { name: _ctx.details.creatorName })), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_a_typography_title, {
            level: 5,
            class: "m-0"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.n(_ctx.parsedDetails.price, "currency", "ph")), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}