
import { CreatorContentSubmissions_creatorContentSubmissions_CreatorContentSubmissionResults_results } from "@/api/creatorContent/__generated__/CreatorContentSubmissions";
import { useVimeoEmbed } from "@/shared/composables/useVimeoEmbed";
import { getCreatorContentDetails } from "@/shared/utils/creatorContentHelper";
import { PropType, computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  props: {
    details:
      Object as PropType<CreatorContentSubmissions_creatorContentSubmissions_CreatorContentSubmissionResults_results>,
  },
  setup(props) {
    const { t, n } = useI18n();

    const { getVimeoThumbnail } = useVimeoEmbed();

    const parsedDetails = computed(() =>
      getCreatorContentDetails(props.details)
    );

    return { t, n, parsedDetails, getVimeoThumbnail };
  },
});
