
import { PropType, computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  props: {
    originalPrice: { type: Number, required: true },
    currentPrice: { type: Number, required: true },
    originalPricePlacement: {
      type: String as PropType<"top" | "bottom">,
      default: "top",
    },
  },
  setup(props) {
    const { t, n } = useI18n();

    return {
      t,
      n,
      showDiscounted: computed(() => props.currentPrice < props.originalPrice),
    };
  },
});
